import { LoadingOutlined } from "@ant-design/icons";
import { ApolloProvider } from "@apollo/client";
import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc";
import { Spin } from "antd";
import PrivateRoute from "components/PrivateRoute";
import {
  authZitadelManager,
  OAUTH2_CLIENT_ID,
  OAUTH2_ENABLED,
  OAUTH2_REDIRECT_URI,
  OAUTH2_SCOPE,
  OAUTH2_SERVER_URL,
} from "config/auth";
import { useFlags } from "flagsmith/react";
import { useAppApolloClient } from "hooks/apollo/apollo";
import Page503 from "pages/PageError/503";
import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.less";
import routes from "./config/routes";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function NewApp() {
  const flags = useFlags(["is_maintenance"]);
  const isMaintenance = flags.is_maintenance.enabled;

  const configuration = {
    client_id: OAUTH2_CLIENT_ID,
    redirect_uri: OAUTH2_REDIRECT_URI,
    scope: OAUTH2_SCOPE,
    authority: OAUTH2_SERVER_URL,
    // service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: false,
    response_type: "id_token",
    response_mode: "query",
    code_challenge_method: "S256",
  };

  const Temp = () => {
    const { apolloClient } = useAppApolloClient();
    return (
      <ApolloProvider client={apolloClient}>
        {isMaintenance ? (
          <Page503 />
        ) : (
          <Router>
            <Switch>
              {routes?.map(({ checkAuth, component: Component, ...rest }, index) => {
                if (checkAuth) {
                  return (
                    <OidcSecure key={index}>
                      <Component {...rest} />
                    </OidcSecure>
                  );
                } else {
                  return <Route key={index} component={Component} {...rest} />;
                }
              })}
            </Switch>
          </Router>
        )}
      </ApolloProvider>
    );
  };

  return (
    <OidcProvider
      configuration={configuration}
      // onEvent={onEvent}
      callbackSuccessComponent={CallBackSuccess}
      authenticatingComponent={CallBackSuccess}
      // loadingComponent={CallBackSuccess}
    >
      <Temp />
    </OidcProvider>
  );
}

const CallBackSuccess = () => {
  return (
    <Spin indicator={antIcon}>
      <div style={{ width: "100vw", height: "100vh" }} />
    </Spin>
  );
};

function OldApp() {
  const { apolloClient } = useAppApolloClient(); // Call the hook at the top level
  const [authInitialized, setAuthInitialized] = useState(false);
  const flags = useFlags(["is_maintenance"]);
  const isMaintenance = flags.is_maintenance.enabled;

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await authZitadelManager.signinRedirectCallback();
        setAuthInitialized(true);
      } catch (error) {
        console.error("Error during auth callback:", error);
        setAuthInitialized(true);
      }
    };

    initializeAuth();
  }, [authZitadelManager]);

  if (!authInitialized) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Spin size="medium"></Spin>
      </div>
    );
  }

  return (
    <ApolloProvider client={apolloClient}>
      {isMaintenance ? (
        <Page503 />
      ) : (
        <Router>
          <Switch>
            {routes?.map((item, index) => {
              if (item?.checkAuth) {
                return <PrivateRoute key={index} {...item} />;
              } else {
                return <Route key={index} {...item} />;
              }
            })}
          </Switch>
        </Router>
      )}
    </ApolloProvider>
  );
}

let App = OldApp;

if (OAUTH2_ENABLED) {
  App = NewApp;
}

export default App;
