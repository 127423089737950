import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { firebaseConfig } from "config/firebase";
import {
  CHANGE_PASSWORD_MUTATION,
  CONFIRM_FORGET_PASSWORD_MUTATION,
  REQUEST_FORGET_PASSWORD_MUTATION,
} from "graphql/auth/mutation";
import { useFirebase } from "hooks/firebase/firebase";
import { useUpdateUserNotification } from "hooks/user/user";

import { authZitadelManager } from "config/auth";
import { TOKEN } from "config/constants";
import { GET_USER_CURRENT } from "graphql/auth/query";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";

export const storageKey = "oidc.user:https://zitadel.dev.honganh.vn:303595230391763018";

// todo change to local storage instead
export const useAuthToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN]);

  const getAuthToken = () => {
    try {
      const storedData = localStorage.getItem(storageKey);
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        return parsedData?.id_token || null;
      }
    } catch (error) {
      console.error("Failed to parse token from localStorage:", error);
    }
    return null;
  };

  const setAuthToken = (authToken) => {
    try {
      localStorage.setItem(storageKey, JSON.stringify(authToken));
    } catch (error) {
      console.error("Failed to set token in localStorage:", error);
    }
  };

  const removeAuthToken = () => {
    localStorage.removeItem(storageKey);
  };

  const token = getAuthToken();
  const tokenNormalLogin = cookies[TOKEN];
  const setAuthNormalToken = (authToken) => setCookie(TOKEN, authToken);
  const removeAuthNormalToken = () => removeCookie(TOKEN);
  const isTokenLoginZitadel = token ? true : false;
  return {
    token: token || tokenNormalLogin,
    isTokenLoginZitadel,
    setAuthToken,
    setAuthNormalToken,
    removeAuthNormalToken,
    getAuthToken,
    removeAuthToken,
  };
};

export const useLogout = () => {
  const apolloClient = useApolloClient();
  const { logoutWithoutApollo } = useLogoutWithoutApollo();
  const { getMessagingToken } = useFirebase(firebaseConfig);
  const { handleUpdateUserNotificationToken } = useUpdateUserNotification();
  const { isTokenLoginZitadel } = useAuthToken();

  const logout = async () => {
    // remove notification token if exist
    const token = await getMessagingToken();
    if (token) {
      try {
        if (isTokenLoginZitadel) {
          await authZitadelManager.signoutRedirect("https://zitadel.dev.honganh.vn/oidc/v1/end_session");
        }
        await handleUpdateUserNotificationToken(token, true);
      } catch (e) {
        console.error(e);
      }
    }

    await apolloClient.clearStore();
    await logoutWithoutApollo();
  };

  return {
    logout,
    logoutWithoutApollo,
  };
};

export const useLogoutWithoutApollo = () => {
  const { removeAuthToken, removeAuthNormalToken } = useAuthToken();
  const history = useHistory();
  const logoutWithoutApollo = async () => {
    if (window.location.pathname !== "/login") {
      history.push({
        pathname: "/login",
      });
    }
    await removeAuthToken();
    await removeAuthNormalToken();
  };

  return {
    logoutWithoutApollo,
  };
};

export const useChangePassword = () => {
  const [mutate, { loading }] = useMutation(CHANGE_PASSWORD_MUTATION);
  const handleChangePassword = async (params) => {
    await mutate({
      variables: params,
    });
  };

  return {
    loading,
    handleChangePassword,
  };
};
export const useRequestForgetPassword = () => {
  const [mutate, { loading }] = useMutation(REQUEST_FORGET_PASSWORD_MUTATION);
  const handleRequestForgetPassword = async (params) => {
    await mutate({
      variables: params,
    });
  };

  return {
    loading,
    handleRequestForgetPassword,
  };
};

export function useConfirmForgetPassword() {
  const [mutate, { loading }] = useMutation(CONFIRM_FORGET_PASSWORD_MUTATION);

  const handleConfirmForgetPassword = async (params) => {
    await mutate({
      variables: params,
    });
  };
  return {
    loading,
    handleConfirmForgetPassword,
  };
}

export const useGetUserCurrent = () => {
  const { data } = useQuery(GET_USER_CURRENT);

  return {
    userCurrentData: data?.user?.me,
  };
};
