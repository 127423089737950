import { useAuthToken } from "hooks/auth/auth";
import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { notify } from "utils/helperFuncs";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { token } = useAuthToken();

  useEffect(() => {
    if (!token) {
      notify.error({
        message: "Tài khoản không hợp lệ.",
        description: "Xin vui lòng đăng nhập lại",
      });
    }
  }, [token]);

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Component key={props.match.params.id || "empty"} {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
