import { Button, Checkbox, Form, Spin } from "antd";

import FormInput from "components/FormInput";
import { authZitadelManager } from "config/auth";
import { INPUT_TYPE } from "config/constants";
import { useFlags } from "flagsmith/react";
import { useAuthToken } from "hooks/auth/auth";
import { useLogin } from "hooks/auth/login";
import { useGetInfoPlatform } from "hooks/info-platform";
import { Link, useHistory } from "react-router-dom";
import { notify } from "utils/helperFuncs";
import "./index.scss";

const Login = () => {
  const history = useHistory();
  const { setAuthNormalToken } = useAuthToken();
  const { handleLogin, loading } = useLogin();
  const flags = useFlags(["is_login_zitadel"]);
  const isLoginZitadel = flags.is_login_zitadel.enabled;

  const formFields = [
    {
      formItemOptions: {
        label: "Email",
        name: "email",
        rules: [{ required: true, message: "Vui lòng nhập email !" }],
      },
      inputOptions: {
        placeholder: "Nhập địa chỉ email của bạn",
        autoFocus: true,
      },
    },
    {
      type: INPUT_TYPE.PASSWORD,
      formItemOptions: {
        label: "Mật khẩu",
        name: "password",
        rules: [{ required: true, message: "Vui lòng nhập mật khẩu !" }],
      },
      inputOptions: {
        placeholder: "Nhập mật khẩu",
      },
    },
  ];

  async function login(values) {
    try {
      const { accessToken } = await handleLogin(values);
      setAuthNormalToken(accessToken);
      notify.success({
        message: "Đăng nhập thành công !",
      });
      history.push("/");
    } catch (err) {
      notify.error({
        message: "Đăng nhập thất bại !",
        description: err?.message,
      });
    }
  }

  const { platformURL, platformName, platformBgURL } = useGetInfoPlatform();

  function loginZitadel() {
    authZitadelManager.signinRedirect();
  }

  return (
    <div className="container">
      <div className="left-col">
        <img src={platformBgURL} alt="LOGIN_BG" />
      </div>
      <div className="right-col">
        <Spin spinning={loading}>
          <div className="form">
            <div className="logo">
              <img src={platformURL} alt={`logo-${platformName}`} />
            </div>
            {/* <p className="greeting">Xin chào!</p> */}
            <p className="form-tile">Đăng nhập tài khoản</p>
            <div className="button-login-group">
              <button onClick={() => loginZitadel()} className="button-login-zitadel">
                <img src={platformURL} alt={`logo-${platformName}`} width={28} />
                <span> Đăng nhập với Hồng Anh</span>
              </button>
            </div>
            {!isLoginZitadel && (
              <Form layout="vertical" className="form-input" onFinish={(values) => login(values)}>
                {formFields.map((field, index) => {
                  return <FormInput key={index} {...field} />;
                })}

                <div className="forgot-password">
                  <Checkbox>Ghi nhớ đăng nhập</Checkbox>
                  <Link to="/forgot-password">Quên mật khẩu</Link>
                </div>

                <Form.Item>
                  <Button className="submit-btn" type="primary" htmlType="submit">
                    Đăng nhập
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Login;
